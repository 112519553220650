<template>
  <div class="manageAccount">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'manageAccount',
  components: {},
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="stylus">

</style>
